import React from "react";
import CLink from "../Components/link";
import ViewManager from "../Components/view-manager";

const FGouvernance = ({ main, secondary }) => {
  const { description, firstname, lastname, job, linkedin_link, picture } =
    main;
  const [ref, visible] = ViewManager(0.01);

  return (
    <div
      ref={ref}
      className={`flexible f-gouvernance container ${
        visible ? "blocInView" : ""
      }`}
    >
      {linkedin_link && (
        <CLink
          withZoom={true}
          type="linkedin"
          url={linkedin_link.url}
          target={linkedin_link.target}
          internal={false}
        >
          <div className="f-gouvernance_card--big">
            <div className="f-gouvernance_card_image--big">
              {picture && <img src={picture.src} alt={picture.alt} />}
            </div>
            <div className="f-gouvernance_card_content">
              <div className="f-gouvernance_card_title">
                <h4>
                  {firstname}
                  <span> {lastname}</span>
                </h4>
                <p>{job}</p>
              </div>
              <div className="f-gouvernance_card_text">{description}</div>
            </div>
          </div>
        </CLink>
      )}
      <div className="f-gouvernance_container subcontainer">
        {secondary &&
          secondary.map(
            (
              { linkedin_link, picture, firstname, lastname, job, description },
              id
            ) => {
              return (
                <div className="f-gouvernance_card" key={id}>
                  <div className="test">
                    <CLink
                      withZoom={true}
                      type="linkedin"
                      url={linkedin_link.url}
                      target={linkedin_link.target}
                      internal={false}
                    >
                      {picture && (
                        <div className="f-gouvernance_card_imageContainer">
                          <img
                            className="f-gouvernance_card_image"
                            src={picture.src}
                            alt={picture.alt}
                          />
                        </div>
                      )}
                    </CLink>
                  </div>
                  <div className="f-gouvernance_card_content">
                    <div className="f-gouvernance_card_title">
                      <h4>
                        {firstname}
                        <span> {lastname}</span>
                      </h4>
                      <p>{job}</p>
                      <div className="description">{description}</div>
                      <span className="linkedin"></span>
                    </div>
                  </div>
                </div>
              );
            }
          )}
      </div>
    </div>
  );
};

export default FGouvernance;
